body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #fafafa;
}
.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0,0,0,.85);
    line-height: 64px;
    background: #fff !important;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #333;
    font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrap {
  max-width: 1000px;
  margin: 24px auto;
}

ol li {
  margin-bottom: 6px;
}

.ant-table-wrapper .ant-table-thead >tr>th {
  text-align: center
}

.inline_form .ant-form-item {
  display: inline-block;
  margin-bottom: 0;
}

.custom-form .ant-typography {
  line-height: 32px;
}

.ant-table-wrapper .ant-table.ant-table-small {
  border: 1px solid;
  border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-begin {
  padding-left: 32px;
}

tr {
  vertical-align: baseline;
}

.ant-space {
  display: flex;
}

.table-footer {
  text-align: right;
}

.ant-btn-primary {
    color: #fff !important;
    border-color: #1da57a !important;
    background: #1da57a !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}

